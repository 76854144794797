import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import image from "../assets/bookimage.jpg"
import style from "./the-isle-of-olympia.module.css"

class TheIsleOfOlympia extends Component {
	render() {
		return (
			<Layout>
				<SEO
					title="The Isle of Olympia"
					description="Alexander the Great’s secret is about to be exposed. Follow Murphy Coleman's life-changing adventure as he discovers the world's best kept secret."
					keywords="Karpasitis, Andreas, Novel, Conspiracy, JFK, Marilyn, Monroe, Writer, olympia, alexander, mystery, davinci code, davinci"
					url="https://www.karpasitis.net/the-isle-of-olympia/"
					isBook
				/>
				<div className={style.container}>
					<header>
						<h1 className={style.title}>The Isle of Olympia</h1>
					</header>
          <div className={style.imageContainer}>
            <a className={style.imageWrapper} href="https://geni.us/the-isle-of-olympia/" target="_blank" rel="noreferrer">
              <img alt="the isle of olympia" src={image} />
            </a>
            <div className={style.textContainer}>
              <h3 className={style.text}>
                <p>Alexander the Great had a secret. The end of his life was just the beginning, his true legacy. His secret had been respected and survived by many great minds throughout the millennia.</p>
                <p>Murphy Coleman, a high-ranking CIA officer gets a taste of this well-kept     secret as his casual meeting with an old acquaintance, turned obsessed conspiracy theorist, gets out of control and evolves into the biggest adventure of his life.</p>
                <p>He embarks on a dangerous and revealing mission with Rachel, a beautiful woman of an extraordinary heritage and her own fair share of mysteries that slowly come to the surface.</p>
              </h3>
              <a href="https://geni.us/the-isle-of-olympia/" target="_blank" rel="noreferrer">
                <button className={style.button}>Buy Now</button>
              </a>
            </div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default TheIsleOfOlympia
